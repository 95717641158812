
import { mapGetters } from "vuex";

export default {
  name: "RichTextBlock",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      backgroundTypes: {
        default: 1,
        color: 2,
        image: 3,
        custom: 4,
      },
    };
  },
  computed: {
    ...mapGetters({
      appBreakpoints: "general/getAppBreakpoints",
    }),
    backgroundStyle() {
      switch (this.data.background_type) {
        case this.backgroundTypes.color: {
          return {
            backgroundColor:
              this.data.color.hexa || this.data.color || "transparent",
          };
        }
        case this.backgroundTypes.custom: {
          return {
            background: this.data.custom_background,
          };
        }
        default: {
          return {};
        }
      }
    },
  },
};
